import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_bxc3455', 'template_bnxdxgf', e.target, 'saBOO2mulME3YKZ2t')
        .then((result) => {
            console.log(result.text);
            alert("Zpráva byla úspěšně odeslána");
            setFormData({
              name: '',
              email: '',
              message: ''
            });
        }, (error) => {
            console.log(error.text);
            alert("Oopsie, vyskytla se chybka, zkuste to znovu");
        });
    };

    return (
    <div id='prihlaska'>
      <h2 className="text-center">Přihlaste se k nám na trénink</h2>
      <div className='d-flex justify-content-center align-items-center vh-75'>    
          <form className="container mt-4 p-4 shadow-lg contact-form" onSubmit={sendEmail}>
            <div className="form-group">
              <label>Jméno:</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                placeholder='Vaše jméno...'
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                placeholder='Váš email...'
                required
              />
            </div>
            <div className="form-group">
              <label>Zpráva:</label>
              <textarea
                name="message"
                className="form-control"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                placeholder='Vaše zpráva...'
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary btn-block mt-3">Odeslat</button>
          </form>
      </div>
    </div>
  );
};

export default ContactForm;